import React from 'react'
import Image from 'gatsby-image'
import { Link } from 'gatsby'
import ComicPage from './comicPage'

export default({data}) => {

  return(
    <div>
      <ComicPage/>

      {data.allMarkdownRemark.edges.map(({node}) => (
          <div key={node.id}>
            <Link to={node.frontmatter.urlPath} title={node.frontmatter.title}>
              <Image fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
            </Link>
            <h1 style={{textAlign: "center"}}>
              {node.frontmatter.title}
            </h1>
          </div>
      ))}
    </div>
  )
}


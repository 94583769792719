import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <title>froggo_introduction</title>
        <g id="pane7">
          <g id="Froggo_copy_6" data-name="Froggo copy 6">
            <ellipse
              cx="492.5"
              cy="673.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M379.5,405.5l5,106-5,123s-4,41,33,41,36-42,36-42,2-18,43-18,44,25,44,25-2,30,31,30,35-17,35-30c0-7.1-4-78.4-4-142,0-53.2,4-101,4-101s0-36-31-36-38,10-38,32-78,29-78,0-23-33-34-32C405.6,362.9,379.5,361.5,379.5,405.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M462.5,442.5s36,6,61,14"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover"
                  d="M462.5,446.5s38-2,63,6"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
              }
            </g>
            <path
              d="M553.5,545.5c51-6-11,46,17,44s81-69,16-86"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M427.5,551.1c23.4.3,67-9.6,85.1-36.4,7.5-11.1,10.6-38.8-108.4,1.8"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <circle
              cx="566.5"
              cy="385.5"
              r={11}
              style={{
                stroke: "#fff",
                strokeMiterlimit: 10,
                strokeWidth: "0.824802008914543px"
              }}
            />
            <circle
              id="open"
              cx="417.5"
              cy="385.5"
              r={11}
              style={{ stroke: "#fff", strokeMiterlimit: 10 }}
            />
            <g id="Layer_14_copy_6" data-name="Layer 14 copy 6">
              <circle cx="466.5" cy="559.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="574.5" cy="467.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={405} cy={437} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={409} cy={598} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={588} cy={622} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="526.5" cy="596.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="404.5" cy="647.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="415.5" cy="464.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={437} cy={517} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={531} cy={418} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={510} cy={490} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="558.5" cy="533.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="585.5" cy="542.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
          </g>
        </g>
      </svg>

    </div>
  )
}
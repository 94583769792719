import React from 'react'
import Introduction from "./froggo/introduction"

export default () => {

  return(
    <div style={{
      boxShadow: '10px 10px 15px 5px lightgrey',
      marginBottom: '50px',
      display: 'flex',
      flexDirection: 'row'
    }}>
      <Introduction/>
    </div>
  )


};
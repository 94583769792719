import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <title>froggo_introduction</title>
        <g id="pane2">
          <g id="Froggo_copy" data-name="Froggo copy">
            <ellipse
              cx="497.5"
              cy="663.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M384.5,395.5l2,146-2,83s-4,41,33,41,36-33,36-33,2-27,43-27,40,25,40,25,2,30,35,30,35-17,35-30c0-5.6-5-53.5-5-104,0-66.5,5-139,5-139s1-39-30-39-39,13-39,35-78,30-78,1-19-33-34-33S384.5,351.5,384.5,395.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M467.5,436.5s23,26,51,2"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="toggle_copy" data-name="toggle copy">
              {hover ?
                <path
                  id="hover"
                  d="M410.9,488c-18.1-14.9-84.6-34.8-89.2-24.7-3.6,7.9-30.2,24.3,85.8,66.2"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover"
                  d="M411.5,488.5c-15-18-76.5-50.1-83-41-5,7-27,19,79,82"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />

              }
            </g>
            <g id="toggle_copy_9" data-name="toggle copy 9">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M568.2,489c18.1-14.9,84.5-34.8,89.2-24.7,3.6,7.9,30.1,24.3-85.9,66.2"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover-2"
                  data-name="nohover"
                  d="M567.5,489.5c15-18,76.5-50.1,83-41,5,7,27,19-79,82"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
              }
            </g>
            <g id="Layer_14_copy" data-name="Layer 14 copy">
              <circle cx="471.5" cy="549.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="579.5" cy="457.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={410} cy={427} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={414} cy={588} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={593} cy={612} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="531.5" cy="586.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="409.5" cy="637.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="420.5" cy="454.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={442} cy={507} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={536} cy={408} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={515} cy={480} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="563.5" cy="523.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="590.5" cy="532.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
            <g id="toggle-2" data-name="toggle">
              {hover ?
                <g id="hover-3" data-name="hover">
                  <circle
                    cx="571.5"
                    cy="369.5"
                    r={11}
                    style={{
                      stroke: "#fff",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.824802008914543px"
                    }}
                  />
                  <circle
                    id="open-2"
                    data-name="open"
                    cx="422.5"
                    cy="370.5"
                    r={11}
                    style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                  />
                </g>
                :
                <g id="nohover-3" data-name="nohover">
                  <circle
                    id="open"
                    cx="422.5"
                    cy="376.5"
                    r={11}
                    style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                  />
                  <circle
                    cx="571.5"
                    cy="375.5"
                    r={11}
                    style={{
                      stroke: "#fff",
                      strokeMiterlimit: 10,
                      strokeWidth: "0.824802008914543px"
                    }}
                  />
                </g>
              }
            </g>
          </g>
          <g id="Layer_15" data-name="Layer 15">
            <text
              transform="translate(271.5 235.7)"
              style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
            >
              My li
              <tspan
                x="83.1"
                y={0}
                style={{ letterSpacing: "-0.009996202256944444em" }}
              >
                f
              </tspan>
              <tspan x={99} y={0}>
                e is filled with ma
              </tspan>
              <tspan
                x="400.8"
                y={0}
                style={{ letterSpacing: "-0.030015733506944444em" }}
              >
                n
              </tspan>
              <tspan
                x="421.5"
                y={0}
                style={{ letterSpacing: "0.00002712673611111111em" }}
              >
                y
              </tspan>
              <tspan x={45} y="43.2">
                e
              </tspan>
              <tspan
                x="65.6"
                y="43.2"
                style={{ letterSpacing: "-0.019992404513888888em" }}
              >
                x
              </tspan>
              <tspan x="85.3" y="43.2">
                citing a
              </tspan>
              <tspan
                x="211.9"
                y="43.2"
                style={{ letterSpacing: "-0.009996202256944444em" }}
              >
                d
              </tspan>
              <tspan
                x="233.1"
                y="43.2"
                style={{ letterSpacing: "-0.029988606770833332em" }}
              >
                v
              </tspan>
              <tspan x={255} y="43.2">
                e
              </tspan>
              <tspan
                x="275.6"
                y="43.2"
                style={{ letterSpacing: "-0.009996202256944444em" }}
              >
                n
              </tspan>
              <tspan x={297} y="43.2">
                tu
              </tspan>
              <tspan
                x={335}
                y="43.2"
                style={{ letterSpacing: "-0.030002170138888888em" }}
              >
                r
              </tspan>
              <tspan x="350.4" y="43.2">
                es!
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}
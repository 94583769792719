import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
     <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
     >
       <title>froggo_introduction</title>
        <g id="pane3">
          <g id="Froggo_copy_2" data-name="Froggo copy 2">
            <ellipse
              cx="502.5"
              cy="649.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M389.5,381.5v33l3,90-3,106s-4,41,33,41,33-38,33-38,11-24,52-24,34,27,34,27,2,30,35,30,35-17,35-30c0-7-.1-77.1-3-140-3-66,3-103,3-103s0-36-31-36-38,10-38,32-74,29-74,0-23-32-38-32S389.5,337.5,389.5,381.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="toggle-3" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M472.5,425.5s39,21,57-2"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover-2"
                  data-name="nohover"
                  d="M472.5,426.5s32,18,57-2"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
              }
            </g>
            <path
              d="M570.1,515.6c35,17.4,52.2,42.1,68.5,19.3s-35.7-49.3-58.8-60.7"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M414.5,492.5c9,38,1,67,29,65s15-59,9-84"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="Layer_14_copy_2" data-name="Layer 14 copy 2">
              <circle cx="476.5" cy="535.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="584.5" cy="443.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={415} cy={413} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={419} cy={574} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={598} cy={598} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="536.5" cy="572.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="414.5" cy="623.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="425.5" cy="440.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={447} cy={493} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={541} cy={394} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={520} cy={466} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="568.5" cy="509.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="595.5" cy="518.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-3"
                  data-name="hover"
                  d="M587.5,362.5c0,6.1-4.9,0-11,0s-11,6.1-11,0a11,11,0,0,1,22,0Z"
                  style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                />
                :
                <circle
                  id="nohover-3"
                  data-name="nohover"
                  cx="576.5"
                  cy="361.5"
                  r={11}
                  style={{
                    stroke: "#fff",
                    strokeMiterlimit: 10,
                    strokeWidth: "0.824802008914543px"
                  }}
                />
              }
              {hover ?
                <path
                  id="hover-4"
                  data-name="hover"
                  d="M438.5,361.5c0,6.1-4.9,0-11,0s-11,6.1-11,0a11,11,0,0,1,22,0Z"
                  style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                />
                :
                <circle
                  id="nohover-4"
                  data-name="nohover"
                  cx="427.5"
                  cy="361.5"
                  r={11}
                  style={{ stroke: "#fff", strokeMiterlimit: 10 }}
                />
              }
            </g>
          </g>
          <text
            transform="translate(304.3 235.5)"
            style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
          >
            <tspan style={{ letterSpacing: "-0.030002170138888888em" }}>P</tspan>
            <tspan x="21.7" y={0}>
              erhaps I should sha
            </tspan>
            <tspan
              x="354.1"
              y={0}
              style={{ letterSpacing: "-0.030002170138888888em" }}
            >
              r
            </tspan>
            <tspan
              x="369.4"
              y={0}
              style={{ letterSpacing: "0.00002712673611111111em" }}
            >
              e
            </tspan>
            <tspan x="65.5" y="43.2">
              some with you!
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}
import React from 'react'
import Pane1 from './pane1'
import Pane2 from './pane2'
import Pane3 from './pane3'
import Pane4 from './pane4'
import Pane5 from './pane5'
import Pane6 from './pane6'
import Pane7 from './pane7'
import Pane8 from './pane8'
import Pane9 from './pane9'


export default () => {


  return(
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex'}}>
        <Pane1/>
        <Pane2/>
        <Pane3/>
      </div>
      <div style={{display: 'flex'}}>
        <Pane4/>
        <Pane5/>
        <Pane6/>
      </div>
      <div style={{display: 'flex'}}>
        <Pane7/>
        <Pane8/>
        <Pane9/>
      </div>
    </div>
  )
}
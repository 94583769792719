import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <title>froggo_introduction</title>
        <g id="pane8">
          <g id="Froggo_copy_7" data-name="Froggo copy 7">
            <ellipse
              cx="492.5"
              cy="663.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M384.5,394.5l-2,67,1,104-4,59s-4,41,33,41,38-32,38-32,0-27,41-27,40,24,40,24,2,30,35,30,35-17,35-30c0-4.5-4.8-34.1-3-72,2-41,0-71.8,0-104,0-37.4,1-68,1-68s1-31-30-31-37,6-37,28-74,29-74,0-23-30-38-30S384.5,350.5,384.5,394.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M461.5,447.5s32-27,55-3"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M528.5,506.5c9,38,1,77,29,75s15-69,9-94"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover-2"
                  data-name="nohover"
                  d="M528.5,506.5c9,38,1,67,29,65s15-59,9-84"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
              }
              {hover ?
                <path
                  id="hover-3"
                  data-name="hover"
                  d="M455.5,513.5c-9,38-5.7,76-33.7,74s-10.3-68-4.3-93"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
                :
                <path
                  id="nohover-3"
                  data-name="nohover"
                  d="M455.8,513.5c-9,38-1,67-29,65s-15-59-9-84"
                  style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
                />
              }
            </g>
            <circle
              cx="566.5"
              cy="388.5"
              r={11}
              style={{
                stroke: "#fff",
                strokeMiterlimit: 10,
                strokeWidth: "0.824802008914543px"
              }}
            />
            <circle
              id="open"
              cx="418.5"
              cy="390.5"
              r={11}
              style={{ stroke: "#fff", strokeMiterlimit: 10 }}
            />
            <g id="Layer_14_copy_7" data-name="Layer 14 copy 7">
              <circle cx="466.5" cy="549.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="574.5" cy="457.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={405} cy={427} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={409} cy={588} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={588} cy={612} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="526.5" cy="586.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="404.5" cy="637.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="415.5" cy="454.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={437} cy={507} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={531} cy={408} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={510} cy={480} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="558.5" cy="523.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="585.5" cy="532.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
          </g>
        </g>
      </svg>

    </div>
  )
}
import React, { useState } from 'react'


export default () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  };

  const onMouseLeave = () => {
    setHover(false)
  };

  return (
    <div
      onMouseOver={() => onMouseOver()}
      onMouseLeave={() => onMouseLeave()}
      role="presentation" // To satisfy the WAI-ARIA accessibility
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="100 100 800 800"
      >
        <title>froggo_introduction</title>
        <g id="pane5">
          <g id="Froggo_copy_4" data-name="Froggo copy 4">
            <ellipse
              cx="500.5"
              cy="657.5"
              rx={119}
              ry={12}
              style={{ fill: "#f2f2f2" }}
            />
            <path
              d="M387.5,389.5l2,131-2,98s-4,41,33,41,36-33,36-33,5-36,43-27c39.9,9.4,40,25,40,25s2,30,35,30,35-17,35-30c0-6.5-2-66.2-2-125s2-118,2-118,.8-27.6-30-31c-18-2-39,5-39,27s-83,28-83-1-14-31-29-31S386.5,345.5,387.5,389.5Z"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M470.5,430.5s-5-15,51,2"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <path
              d="M536.5,500.5c9,38,1,67,29,65s15-59,9-84"
              style={{ fill: "#22b573", stroke: "#000", strokeMiterlimit: 10 }}
            />
            <g id="toggle-4" data-name="toggle">
              {hover ?
                <path
                  id="hover-2"
                  data-name="hover"
                  d="M458,526.4c15.8-17.3,32.5-80.9,25.1-88.8s-27.3-25.7-70.6,88.9"
                  style={{
                    fill: "#22b573",
                    stroke: "#000",
                    strokeMiterlimit: 10,
                    strokeWidth: "1.04938805103302px"
                  }}
                />
                :
                <path
                  id="nohover-2"
                  data-name="nohover"
                  d="M458.6,526.6c13-19.5,20.3-84.8,11.8-91.5S439,407,412.9,526.7"
                  style={{
                    fill: "#22b573",
                    stroke: "#000",
                    strokeMiterlimit: 10,
                    strokeWidth: "1.04938805103302px"
                  }}
                />
              }
            </g>
            <circle
              cx="574.5"
              cy="370.5"
              r={11}
              style={{
                stroke: "#fff",
                strokeMiterlimit: 10,
                strokeWidth: "0.824802008914543px"
              }}
            />
            <circle
              id="open"
              cx="425.5"
              cy="363.5"
              r={11}
              style={{ stroke: "#fff", strokeMiterlimit: 10 }}
            />
            <g id="Layer_14_copy_4" data-name="Layer 14 copy 4">
              <circle cx="474.5" cy="543.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="582.5" cy="451.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx={413} cy={421} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={417} cy={582} r={4} style={{ fill: "#a5c447" }} />
              <circle cx={596} cy={606} r={3} style={{ fill: "#a5c447" }} />
              <circle cx="534.5" cy="580.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx="412.5" cy="631.5" r="3.5" style={{ fill: "#a5c447" }} />
              <circle cx="423.5" cy="448.5" r="2.5" style={{ fill: "#a5c447" }} />
              <circle cx={445} cy={501} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={539} cy={402} r={2} style={{ fill: "#a5c447" }} />
              <circle cx={518} cy={474} r={2} style={{ fill: "#a5c447" }} />
              <circle cx="566.5" cy="517.5" r="1.5" style={{ fill: "#a5c447" }} />
              <circle cx="593.5" cy="526.5" r="2.5" style={{ fill: "#a5c447" }} />
            </g>
          </g>
        </g>
        <g id="pane6">
          <text
            transform="translate(421.4 247.1)"
            style={{ fontSize: 36, fontFamily: "FredokaOne-Regular, Fredoka One" }}
          >
            Mmmmm...
          </text>
        </g>
      </svg>


    </div>
  )
}